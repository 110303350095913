import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"
import tw, { styled } from "twin.macro"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Section from "../components/section/section"
import ArticleLargeCard from "../components/article/article-large-card"

const _ = require("lodash")

const ArticleCards = styled.div`
    ${tw`flex flex-wrap -mx-3 lg:-mx-6 -mt-6`}
`

const Pagination = styled.nav`
    ${tw`text-center text-white mt-6 py-4`}
`

const PaginationList = styled.ul`
    ${tw`list-none inline-block`}
`

const PaginationLink = styled.li`
    ${tw`float-left`}
`

const StyledLink = styled(Link)`
    ${tw`w-full h-full block w-12 h-12 p-2 bg-gray-400 rounded-lg hover:bg-psb-blue-200
        transition-colors duration-300 focus:outline-none focus:bg-psb-blue-200 text-white text-lg font-bold`}
`

const TagTemplate = ({ data, pageContext, location }) => {
    const { tag, currentPage, numberOfPages } = pageContext
    const catPath = `/tag/${_.kebabCase(tag)}`

    const isFirstPage = currentPage === 1
    const isLastPage = currentPage === numberOfPages
    const previousPage = currentPage - 1 === 1 ? catPath : `${catPath}/${(currentPage - 1).toString()}`
    const nextPage = `${catPath}/${(currentPage + 1).toString()}`
    
    const { edges } = data.allMdx

    return (
        <Layout>
            <SEO
                title={tag}
                pathname={location.pathname}
                index="noindex"
            />
            <Hero title={tag} description="" image={data.hero.childImageSharp.fluid} />
            <Section>
                <ArticleCards>
                    {edges.map(({ node }) => (
                        <ArticleLargeCard
                            title={node.frontmatter.title}
                            date={node.frontmatter.date}
                            categories={node.frontmatter.categories}
                            tags={node.frontmatter.tags}
                            featuredImage={node.frontmatter.featuredImage.childImageSharp.fluid}
                            slug={node.fields.slug} />
                    ))}
                    </ArticleCards>
                    <Pagination>
                        <PaginationList>
                            {!isFirstPage && (
                                <PaginationLink>
                                    <button>
                                        <StyledLink
                                            to={previousPage}
                                            rel="prev"
                                            activeStyle={{ backgroundColor: "rgba(255,159,10,var(--bg-opacity))"}}>
                                                &lt;
                                        </StyledLink>
                                    </button>
                                </PaginationLink>
                            )}
                            {Array.from({ length: numberOfPages }, (_, i) => (
                                <PaginationLink>
                                    <button>
                                        <StyledLink
                                            key={`pagination-number${i + 1}`}
                                            to={`${catPath}${ i === 0 ? "" : "/" +(i + 1)}`}
                                            activeStyle={{ backgroundColor: "rgba(255,159,10,var(--bg-opacity))"}}>
                                                {i + 1}
                                        </StyledLink>
                                    </button>
                                </PaginationLink>
                            ))}
                            {!isLastPage && (
                                <PaginationLink>
                                    <button>
                                        <StyledLink
                                            to={nextPage}
                                            rel="next"
                                            activeStyle={{ backgroundColor: "rgba(255,159,10,var(--bg-opacity))"}}>
                                                &gt;
                                        </StyledLink>
                                    </button>
                                </PaginationLink>
                            )}
                        </PaginationList>
                    </Pagination>
            </Section>
        </Layout>
    )
}

TagTemplate.propTypes = {
    pageContext: PropTypes.shape({
        category: PropTypes.string.isRequired,
    }),
    data: PropTypes.shape({
        allMdx: PropTypes.shape({
            edges: PropTypes.arrayOf(
                PropTypes.shape({
                    node: PropTypes.shape({
                        frontmatter: PropTypes.shape({
                            title: PropTypes.string.isRequired,
                        }),
                        fields: PropTypes.shape({
                            slug: PropTypes.string.isRequired,
                        }),
                    }),
                }).isRequired
            ),
        }),
    }),
}

export default TagTemplate

export const tagPageListQuery = graphql`
    query tagPageListQuery($tag: String, $skip: Int!, $limit: Int!) {
        allMdx (
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { tags: { in: [$tag] }, published: { eq: true } } }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        date(formatString: "DD MMMM, YYYY")
                        categories
                        tags
                        featuredImage {
                            childImageSharp {
                                fluid(maxWidth: 1200) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
        hero: file(relativePath: { eq: "mirage-searching_400.png" }) {
            childImageSharp {
                fluid(maxWidth: 1216 quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`